<template>
  <div id="app">
    <div class="title_bg"></div>
    <Header />

    <template>
    <v-container fluid>
      <v-row dense class="mt-n2 ml-n3 mr-n3">
        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4"
          v-for="(coupon, index) in coupons"
          :key="index">

          <v-card
            justify="center"
            class="mx-auto"
            max-width="400px"
            width="100%"
            dark
            flat
            tile>
            <v-img v-if="coupon.ref_type=='02'"
              contain
              :src="coupon.ref_image"
              width="100%">
            </v-img>

            <v-img v-else-if="coupon.no_coupon"
              contain
              :src="require(`@/assets/${coupon.ref_image}`)"
              width="100%">
            </v-img>

            <v-img v-else
              contain
              :src="coupon.ref_image"
              width="100%">
              <div class="div_point ml-4">
                <!-- <div class="value_coupon">99,999</div> -->
                <div class="value_coupon"> {{ numberFormat(coupon.cal_give ?? 0, 0) }}</div>
                <div class="label_coupon">{{ $t('home.expire_date') }}<br />{{ coupon.exp_date }}</div>
              </div>
            </v-img>

            <v-card-title class="div_title">
              <span v-html='coupon.ref_title'></span>
            </v-card-title>

            <v-card-subtitle class="div_condition mt-0">
              {{ $t('home.condition') }} : <span v-html='coupon.ref_condition'></span><br/>
              {{ $t('home.expire_date') }} : {{ coupon.exp_date }}
            </v-card-subtitle>

            <!-- <v-card-actions>
              <v-btn
                color="orange lighten-2"
                text
              >
                รายละเอียด
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions> -->

            <!-- <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>

                <v-card-text>
                  {{ coupon.ref_condition }}
                </v-card-text>
              </div>
            </v-expand-transition> -->
          </v-card>

        </v-col>
      </v-row>
    </v-container>    
    </template>

    <Bottom />
  </div>
</template>

<script>
import { server } from "@/services/constants";
import Header from "@/components/Header"
import Bottom from "@/components/Bottom"
import api from "@/services/api_villa";

export default {
  name: "Rewards",
  data() {
    return {
      isShowOverlay: false,
      coupons: [],
      show: false,
    };
  },
  components: {
    Header,
    Bottom
  },
  methods: {
    numberFormat(value, decimal) {
      return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
    },
  },
  async mounted() {
    try {
      this.isShowOverlay = true;
      // this.coupons = await api.getCoupon("0618276222");
      this.coupons = await api.getCoupon(this.phoneNo);
      // this.coupons = await api.getCoupon('phone-id-69EC6052-DF4F-4A99-9FD1-63E8391CE26E08092022114422094902259177E131DA-A19D-4C4D-8ACF-609EDB748522');
      // if (this.coupons.length == 0) {
      //   for (let i = 0; i < 3; i++) {
      //     this.coupons.push({ 
      //         "no_coupon": true,
      //         "ref_image": "no_coupon_".concat(i+1).concat(".png"),
      //         "exp_date": "",
      //         "ref_title": "",
      //         "ref_condition": ""
      //       }
      //     );
      //   }
      // }
      // console.log('-- coupons --', this.coupons);
    } catch (error) {
      console.log(error);
    }
    finally {
      setTimeout(() => {
        this.isShowOverlay = false;
      }, 1000);
    }
  },
  computed: {
    phoneNo() {
      return localStorage.getItem(server.TOKEN_KEY);
      // let encryptedText = localStorage.getItem(server.TOKEN_KEY);
      // return this.$CryptoJS.AES.decrypt(encryptedText, server.TOKEN_PASSWORD).toString(this.CryptoJS.enc.Utf8);
    }
  }
}

</script>

<style scoped>
.title_bg {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
    color: #ffffff;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%);
    background-size:100% 100%;
    background-repeat: no-repeat;
    position: fixed;
}
.div_point {
    width: 100%;
    text-align: center;
    max-width: 400px;
}
.div_title {
    width: 100%;
    max-width: 400px;
    font-size: 0.9rem !important;
}
.div_condition {
    width: 100%;
    max-width: 400px;
    font-size: 0.8rem !important;
}
.value_coupon {
    width: 100%;
    text-align: center;
    max-width: 400px;
    margin-top: 50px;
    font-size: 6rem !important;
    font-weight: bold;
    position: absolute;
    color: #0D47A1;
}
.label_coupon {
    width: 100%;
    text-align: center;
    max-width: 400px;
    margin-top: 170px;
    font-size: 1.2rem !important;
    position: absolute;
    color: #B71C1C;
}
</style>